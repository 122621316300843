import React, { useState } from 'react';
import styles from './NewsLetter.module.scss';
import NewsCard from '../../../../../../molecule/newsCard/NewsCard';
import Text from '../../../../../../atoms/text/Text';
import arowDown from '../../../../../../../assets/icons/downArrow.svg';
import { HorizontalCard } from '../../../../../../molecule/card/Card';
import Button from '../../../../../../atoms/button/Button';
import i18next from 'i18next';
import { useGetQuery } from '../../../../../../../services/apiCall';
import endpoints from '../../../../../../../api/endpoints';
import Loader from '../../../../../../atoms/loader/Loader';
import { useNavigate } from 'react-router-dom';

const NewsLetter = () => {
  const navigate = useNavigate();

  const currentLanguage = i18next.language;

  const apiUrl = endpoints.news.getNewsletter;
  const params = ['3', '1'];
  const { data, error, isLoading, refetch } = useGetQuery({
    apiUrl: apiUrl,
    params: params,
  });
  const formatDate = (isoDateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(isoDateString).toLocaleDateString(
      currentLanguage === 'en' ? 'en-US' : 'ar-EG',
      options
    );

    return formattedDate.replace(/-|\./g, '/');
  };
  if (isLoading) return <Loader />;
  if (!data?.items?.length) return <></>;

  return (
    <div className={styles['letter-container']}>
      <div className={styles.grid}>
        <HorizontalCard
          contentClass={styles['card-content']}
          className={styles.card}
          imageClass={styles.imageClass}
          date={
            data?.items[0]?.newsletterDate
              ? formatDate(data?.items[0]?.newsletterDate)
              : null
          }
          title={
            currentLanguage === 'en'
              ? data?.items[0]?.title_EN
              : data?.items[0]?.title_AR
          }
          description={
            currentLanguage === 'en'
              ? data?.items[0]?.body_EN
              : data?.items[0]?.body_AR
          }
          imageUrl={`${data?.items[0]?.url}${data?.items[0]?.newsletterImage}`}
        />
      </div>
      <Button
        title="home.view_all_NewsLetter"
        variant="text-regular-20"
        style={{ textTransform: 'uppercase' }}
        styleType="primary"
        className={styles['view-all-button']}
        svg={arowDown}
        svgPosition="right"
        onClick={() => navigate('/news-and-events/news-letter')}
      />
      <Text
        title="home.view_all_NewsLetter"
        variant="text-bold-italic-16"
        className={styles['view-all']}
        svg={arowDown}
        svgPosition="right"
        onClick={() => navigate('/news-and-events/news-letter')}
      />
    </div>
  );
};

export default NewsLetter;
