import React, { useState } from 'react';
import { MobileMenu } from './factory/Factory';
import styles from './NavigationMobile.module.scss';
import RoundedButton from '../../../atoms/roudedButton/RoundedButton';
import accessibilitySVG from '../../../../assets/icons/accessibility.svg';
import govLogo from '../../../../assets/icons/gov-logo.svg';
import LanguageToggle from '../../../atoms/languageToggle/LanguageToggle';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAccessModal } from '../../../../app/globals/slice/sideModalSlice/SideModalSlice';
const NavigationMobile = ({ data, isRtl }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access } = useSelector((state) => state.sideModal);
  const handleAccessibility = (flag) => {
    dispatch(toggleAccessModal());
  };
  return (
    <>
      <nav className={isRtl ? `${styles.nav} rtl` : styles.nav}>
        <div className={styles.container}>
          <MobileMenu className={styles.menu} data={data} isRtl={isRtl} />
        </div>
      </nav>

      <div className={styles['buttons-mobile']}>
        <div className={styles['button-wrapper']}>
          <LanguageToggle />
          <RoundedButton
            svg={accessibilitySVG}
            onClick={() => handleAccessibility(true)}
            isbordered
          />
          <RoundedButton
            title="home.Sign_In"
            variant="text-regular-20"
            // onClick={handleSignInModal}
            onClick={() => window.open('https://dpa.blackboard.com/', '_blank')}
            buttonClass={styles.signin}
            maxline={1}
          />
        </div>
        <img
          src={govLogo}
          alt="dubai police Logo"
          // onClick={() =>
          //   window.open('https://we.tl/t-hZMjSLxm4d', '_blank')
          // }
          onClick={() => navigate('/')}
          className={styles['gov-logo']}
        />
      </div>
    </>
  );
};

export default NavigationMobile;
