import React, { useState } from 'react';
import styles from './Latest.module.scss';
import Text from '../../../atoms/text/Text';
import { Menu } from './factory/menu/Menu';
import { BarData } from './factory/BarData';
import News from './factory/components/news/News';
import { useTranslation } from 'react-i18next';
import backgroundImage from '../../../../assets/images/home/common/primaryGirl/dummyImage.jpg';
import Backdrop from '../../../atoms/backdrop/Backdrop';

const Latest = () => {
  const { i18n } = useTranslation();
  const [content, setContent] = useState({
    title: 'home.news',
    component: <News />,
  });

  const isRtl = i18n.language !== 'en';
  return (
    <div className={styles['Latest-container']}>
      <img
        src={backgroundImage}
        alt=""
        className={styles['background-image']}
        loading="lazy"
      />
      <Backdrop />
      <div className={styles['latest']}>
        <Text
          title="home.academy_latest"
          variant="text-bold-italic-40"
          className={styles.title}
          textContainerStyle={{
            textAlign: 'center',
            marginBottom: isRtl && '10px',
          }}
        />

        <div className={`${styles['latest-view']} ${isRtl ? 'rtl' : ''}`}>
          <Menu data={BarData} setContent={setContent} />

          <div className={styles.container}>
            <div>
              <div className={styles.text}>
                <Text
                  title={content.title}
                  variant="text-bold-italic-22"
                  style={{
                    textTransform: 'uppercase',
                    display: isRtl && 'none',
                  }}
                />
              </div>
            </div>

            <div
              className={`${styles.contents} ${isRtl ? styles.noBorders : ''}`}
            >
              {content.component}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Latest;
