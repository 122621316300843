import React, { useState } from 'react';
import styles from './PageHeader.module.scss';
import BottomNavigation from '../../../components/molecule/bottomNavigation/BottomNavigation';
import { HeadingWithSubtext } from '../../../components/molecule/headingWithSubtext/HeadingWithSubtext';
import { useTranslation } from 'react-i18next';
import LazyVideo from '../../atoms/lazyVideo/LazyVideo';
import { useMediaQuery } from 'react-responsive';
import defaultImage from '../../../assets/images/defaultNews.png';
import Loader from '../../atoms/loader/Loader';
import HeaderSection from '../../organism/headerSection/HeaderSection';

const PageHeader = ({
  data,
  navigationData,
  handleButtonClick,
  section,
  component,
  style,
  hasVideo,
  videoUrl,
  isApplyNow,
  headingClassname,
  isBackdroped = true,
  isAlwaysLtr,
  navigationStyle,
  backdropStyle,
  isLoaded,
  autoBackground
}) => {
  const isTablet = useMediaQuery({ maxWidth: 1200 });
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [backgroundImage, setBackgroundImage] = useState(
    isMobile ? data?.mobileImg ?? data?.img : data?.img ?? defaultImage
  );
  const isRtl = currentLanguage !== 'en';

  const titleClassName = `${styles['title']} ${isRtl ? 'rtl' : ''} ${
    hasVideo ? styles.absolute : ''
  }`;
  const handleError = (e) => {
    e.target.src = defaultImage;
    setBackgroundImage(defaultImage);
  };

  return (
    <header style={{ direction: isAlwaysLtr ? 'ltr' : '' }}>
      {isLoaded ? (
        <>
          <HeaderSection />
          <Loader fullPage />
        </>
      ) : null}

      <div className={styles.container} style={style}>
        <img
          src={data?.img}
          className={styles.hidden}
          onError={handleError}
          alt="header image"
          loading="lazy"
        />

        {!data?.img?.left && data?.img ? (
          <div
            className={`${styles.backImage} ${autoBackground ? styles.autoBackground : ''}`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
              // backgroundPosition: isTablet
              //   ? `${positioningLeft ?? ''} ${positioningRight ?? ''}`
              //   : '',
            }}
            alt="header image"
          />
        ) : null}
        {isBackdroped ? (
          <div className={styles.backdrop} style={backdropStyle}></div>
        ) : null}
        <div className={titleClassName}>
          <HeadingWithSubtext
            className={`${styles['heading-class']} ${headingClassname}`}
            title={data?.title}
            subtext={data?.subtext}
            component={component}
            subtextStyle={{ color: 'white', opacity: 1 }}
            maxLines={10}
          />
        </div>
        {hasVideo && <LazyVideo src={videoUrl} />}
        {!isTablet && data?.img?.left ? (
          <div className={styles['degreeType-image']}>
            <img src={data?.img?.left} className={styles.left} loading="lazy" />
            <img
              src={data?.img?.right}
              className={styles.right}
              loading="lazy"
            />
          </div>
        ) : null}
        {navigationData ? (
          <BottomNavigation
            data={navigationData}
            variant="text-bold-20"
            onClick={handleButtonClick}
            active={section}
            isApplyNow={isApplyNow}
            navigationStyle={{ padding: '0rem 2rem', ...navigationStyle }}
          />
        ) : null}
      </div>
    </header>
  );
};

export default PageHeader;
