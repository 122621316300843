import React from 'react';
import styles from './StudentProfile.module.scss';
import PageHeader from '../../components/templates/pageHeader/PageHeader';
import HeaderSection from '../../components/organism/headerSection/HeaderSection';
import notFound from '../../assets/images/NotFound.png';
import Text from '../../components/atoms/text/Text';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import homeIcon from '../../assets/images/blachHomeIcon.svg';
import arrow from '../../assets/images/pathSingleTeam.svg';
import Footer from '../../components/templates/homePageSections/footer/Footer';
import useScrollToTop from '../../app/hooks/useScrollToTop';
const StudentProfile = () => {
  useScrollToTop();
  const { i18n } = useTranslation();
  const currentlanguage = i18n.language;
  const isRtl = currentlanguage !== 'ar';
  const location = useLocation();
  const navigate = useNavigate();
  const studentData = location.state?.student;

  const img = studentData?.facultyImage
    ? studentData?.url + studentData?.facultyImage
    : notFound;

  const handleBackClick = () => {
    navigate('/about/team');
  };
  return (
    <div className={styles['student-information']}>
      <HeaderSection />
      <img src={img} className={styles.profileImage} />
      <PageHeader
        style={{ height: '91vh' }}
        data={{ title: 'Team.General_Information' }}
        component={
          <div className={styles['general-info']}>
            <div className={styles['text-container']}>
              <div className={styles.text}>
                <Text title={'Team.Name'} variant="text-bold-18" />
                <Text title=": " variant="text-bold-18" />
              </div>
              <Text
                title={
                  studentData
                    ? `${
                        isRtl
                          ? studentData.fullName_EN
                          : studentData.fullName_AR
                      }`
                    : ''
                }
                variant="text-bold-18"
              />
            </div>

            <div className={styles['text-container']}>
              <div className={styles.text}>
                <Text title={'Team.Position'} variant="text-bold-18" />{' '}
                <Text title=": " variant="text-bold-18" />
              </div>
              <Text
                title={
                  studentData
                    ? `${
                        isRtl ? studentData.positionEN : studentData.positionAR
                      }`
                    : ''
                }
                variant="text-bold-18"
              />
            </div>

            <div className={styles['text-container']}>
              <div className={styles.text}>
                <Text title={'Team.Academic_Degree'} variant="text-bold-18" />{' '}
                <Text title=": " variant="text-bold-18" />
              </div>
              <Text
                title={
                  studentData
                    ? `${
                        isRtl
                          ? `${studentData.educationEN}`
                          : `${studentData.educationAR}`
                      }`
                    : ''
                }
                variant="text-bold-18"
              />
            </div>

            <div className={styles['text-container']}>
              <div className={styles.text}>
                <Text title={'Team.Languages'} variant="text-bold-18" />{' '}
                <Text title=": " variant="text-bold-18" />
              </div>
              <Text
                title={studentData ? ` ${studentData.languages}` : ''}
                variant="text-bold-18"
              />
            </div>

            {/* <div className={styles['text-container']}>
              <div className={styles.text}>
                <Text title={'Team.Experience'} variant="text-bold-18" />
              </div>
              <Text
                title={
                  studentData
                    ? `: ${
                        isRtl
                          ? studentData.experienceEn || ''
                          : studentData.experienceAr || ''
                      }`
                    : ': '
                }
                variant="text-bold-18"
              />
            </div> */}

            <div className={styles['text-container']}>
              <div className={styles.text}>
                <Text title={'Team.Email'} variant="text-bold-18" />{' '}
                <Text title=": " variant="text-bold-18" />
              </div>
              <Text
                title={studentData ? `${studentData.email}` : ''}
                variant="text-bold-18"
              />
            </div>
          </div>
        }
      />
      <div className={styles['navigation-container']}>
        <div className={styles.buttonSvg}>
          <div onClick={handleBackClick} className={styles.profileImageButton}>
            <img
              src={homeIcon}
              alt="Profile"
              className={styles.profilesvgs}
              onClick={handleBackClick}
            />
          </div>
          <Text
            title="home.team"
            variant="text-bold-20-black"
            style={{ textTransform: 'uppercase' }}
          />
        </div>
        <div className={styles.buttonSvg}>
          <img src={arrow} alt="arrow" />
          <Text
            title={
              studentData
                ? `${isRtl ? studentData.fullName_EN : studentData.fullName_AR}`
                : ''
            }
            variant="text-bold-20-black"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentProfile;
