import React from 'react';
import styles from './StudentCard.module.scss';
import Text from '../../atoms/text/Text';
import messageUnhovered from '../../../assets/icons/profileCardItems/messageUnhovered.svg';
import phoneUnhover from '../../../assets/icons/profileCardItems/phoneUnhover.svg';
import infoUnhovered from '../../../assets/icons/profileCardItems/infoUnhovered.svg';
import { useTranslation } from 'react-i18next';

import imageNowFound from '../../../assets/images/NotFound.png';
import isRtl from '../../../app/helpers/isRtl';
import { sanitizeAndValidateUrl } from '../../../app/helpers/sanitizeAndValidateUrl';

const StudentCard = ({
  img,
  name,
  bio,
  position,
  member,
  showInfo = true,
  cardImageClass,
  onClick,
  isMessage = true,
  isPhone = true,
  imageId = '',
  titleColor,
  imageStyle,
  email,
}) => {
  const cardImageClasses = `${styles['card-image']} ${styles[cardImageClass]}`;
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRtl = currentLanguage !== 'en';
  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`;
  };
  const sanitizedImageUrl = sanitizeAndValidateUrl(img);

  return (
    <div className={styles['card-container']}>
      <div className={cardImageClasses} style={imageStyle}>
        <img
          src={imageId ? sanitizedImageUrl : imageNowFound}
          alt="dfs"
          className={styles.primeImage}
        />

        {showInfo && (
          <div className={styles['icons-container']}>
            <div className={styles.item} onClick={onClick}>
              <img src={infoUnhovered} alt="Item 1" />
            </div>
            <div className={styles.separator}></div>
            {isMessage && (
              <div className={styles.item} onClick={handleEmailClick}>
                <img src={messageUnhovered} alt="Item 2" />
              </div>
            )}
            {isPhone && <div className={styles.separator}></div>}
            {isPhone && (
              <div className={styles.item}>
                <img src={phoneUnhover} alt="Item 3" />
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className={styles['card']}
        style={{
          textAlign: isRtl ? 'right' : '',
          direction: isRtl ? 'rtl' : '',
        }}
      >
        <div className={styles['info']}>
          <Text
            title="Team.Name"
            variant="text-regular-18-green"
            style={{ textTransform: 'uppercase', cursor: 'pointer' }}
            className={styles['title']}
            color={titleColor}
          />
          <Text
            title={name}
            variant="text-regular-16-black"
            style={{ cursor: 'pointer', opacity: 0.7 }}
            className={styles['text']}
            maxLines={2}
          />
        </div>
        <div className={styles['info']}>
          <Text
            title="Team.Position"
            variant="text-regular-18-green"
            style={{ textTransform: 'uppercase', cursor: 'pointer' }}
            className={styles['title']}
            color={titleColor}
          />
          <Text
            title={position}
            variant="text-regular-16-black"
            style={{ cursor: 'pointer', opacity: 0.7 }}
            className={styles['text']}
            maxLines={3}
          />
        </div>
        {bio ? (
          <div className={styles['info']}>
            <Text
              title="Bio"
              variant="text-regular-18-green"
              style={{ textTransform: 'uppercase', cursor: 'pointer' }}
              className={styles['text']}
            />

            <Text
              title={bio}
              variant="text-regular-16-black"
              style={{ cursor: 'pointer', opacity: 0.7 }}
              className={styles['text']}
            />
          </div>
        ) : null}
        {member ? (
          <div className={styles['info']}>
            <Text
              title="students_council.members_cat"
              variant="text-regular-18-green"
              style={{ textTransform: 'uppercase', cursor: 'pointer' }}
              className={styles['title']}
              color={titleColor}
            />

            <Text
              title={member}
              variant="text-regular-16-black"
              style={{ cursor: 'pointer', opacity: 0.7 }}
              className={styles['text']}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StudentCard;
