const commmonLinks = [
  { label: 'bottomNavigation.News', route: '/news-and-events/news' },
  { label: 'bottomNavigation.Events', route: '/news-and-events/events' },
  {
    label: 'bottomNavigation.NewsLetter',
    route: '/news-and-events/news-letter',
  },
  {
    label: 'bottomNavigation.MediaGallery',
    route: '/news-and-events/media-gallery',
  },
];

export { commmonLinks };
