import React from 'react';
// import News from '../../../../organism/newsBar/sections/news/News';
// import NewsLetter from '../../../../organism/newsBar/sections/newsLetter/NewsLetter';
import image from '../../../../../assets/images/library.png';
// import GeneralCalendar from '../../../../organism/newsBar/sections/academyCalendar/generalCalendar/GeneralCalendar';
// import Events from '../../../../organism/newsBar/sections/events/Events';

import images from '../../../../../assets/images/dummy.png';
import News from './components/news/News';
import Events from './components/events/Events';
import NewsLetter from './components/newsLetter/NewsLetter';
import Calendar from './components/calendar/Calendar';

const eventsData = [
  {
    date: '06 / 08 / 2023',
    time: '07 : 00 AM',
    categoryName: 'Category A',
    address: 'google//google/maps/12322222',
    title: 'Event A Title',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
    leftImage: images,
    rightImage: images,
  },
  {
    date: '07 / 08 / 2023',
    time: '08 : 00 AM',
    categoryName: 'Category B',
    address: 'google//google/maps/12333333',
    title: 'Event B Title',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
    leftImage: images,
    rightImage: images,
  },
  {
    date: '08 / 08 / 2023',
    time: '09 : 00 AM',
    categoryName: 'Category C',
    address: 'google//google/maps/12344444',
    title: 'Event C Title',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
    leftImage: images,
    rightImage: images,
  },
];
const BarData = [
  { title: 'home.news', component: <News /> },
  {
    title: 'home.events',
    component: <Events />,
  },
  {
    title: 'home.newsletter',
    component: <NewsLetter />,
  },
  {
    title: 'home.academic_calendar',
    children: [
      {
        title: 'home.general_calendar',
        component: <Calendar type="general" />,
      },
      {
        title: 'home.higher_education_calendar',
        component: <Calendar type="higherEducation" />,
      },
      {
        title: 'home.evening_studies_calendar',
        component: <Calendar type="eveningStudies" />,
      },
      { title: 'home.cadets_calendar', component: <Calendar type="cadetsCalendar" /> },
    ],
  },
];

export { BarData, eventsData };
