import React, { useEffect, useRef } from 'react';
import styles from './AcademyInNumber.module.scss';
import ScrollReveal from 'scrollreveal';
import Text from '../../../atoms/text/Text';
import Button from '../../../atoms/button/Button';
import RewardCardGrid from '../../../molecule/rewardCardGrid/RewardCardGrid';
import quality from '../../../../assets/icons/ratings/quality.svg';
import document from '../../../../assets/icons/ratings/document.svg';
import Graduates from '../../../../assets/icons/ratings/Graduates.svg';
import Conference from '../../../../assets/icons/ratings/Conference.svg';
import Students from '../../../../assets/icons/ratings/Students.svg';
import Award from '../../../../assets/icons/ratings/Award.svg';
import Partnerships from '../../../../assets/icons/ratings/Partnerships.svg';
import meeting from '../../../../assets/icons/ratings/meeting-room.svg';
import checklist from '../../../../assets/icons/ratings/checklist.svg';
import research from '../../../../assets/icons/ratings/research.svg';
import backgroundImage from '../../../../assets/images/home/academy-in-numbers/academy-in-numbers.jpg';
import { useTranslation } from 'react-i18next';
import ImageWithBackdrop from '../../../molecule/imageWithBackdrop/ImageWithBackdrop';
import { useNavigate } from 'react-router-dom';

const SECTION = 7;

const data = [
  { number: '3', title: 'home.ranking', svg: quality },
  { number: '7', title: 'home.accreditation', svg: document },
  { number: '10', title: 'home.award', svg: Award },
  { number: '13', title: 'home.partnerships', svg: Partnerships },
  { number: '12', title: 'home.researches', svg: research },
  { number: '500', title: 'home.graduates', svg: Graduates },
  { number: '7000', title: 'home.students', svg: Students },
  { number: '40', title: 'home.programs', svg: checklist },
  { number: '8', title: 'home.conference', svg: Conference },
  { number: '8', title: 'home.workshops', svg: meeting },
];
const AcademyInNumber = ({ currentSection }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const isRtl = i18n.language !== 'en';
  const headerRef = useRef(null);
  const contentRef = useRef(null);
  const rightRef = useRef(null);
  const buttonMobileRef = useRef(null);

  useEffect(() => {
    const srConfig = {
      delay: 100,
      duration: 500,
      easing: 'ease-in-out',
      opacity: 0,
      scale: 0.9,
      distance: '20px',
      reset: true,
    };

    if (headerRef.current) {
      ScrollReveal().reveal(headerRef.current, srConfig);
    }
    if (contentRef.current) {
      ScrollReveal().reveal(contentRef.current, srConfig);
    }
    if (rightRef.current) {
      ScrollReveal().reveal(rightRef.current, srConfig);
    }
    if (buttonMobileRef.current) {
      ScrollReveal().reveal(buttonMobileRef.current, srConfig);
    }
  }, []);

  const handleNavigate = () => {
    navigate('/academy-in-number');
  };
  return (
    <div
      className={`${styles['academy-in-number']} ${isRtl ? styles.rtl : ''}`}
    >
      <ImageWithBackdrop
        src={backgroundImage}
        alt="academy in numbers"
        backdropStyle={{ zIndex: -1 }}
        currentSection={currentSection === SECTION}
        scale="out"
        isAnimated
      />
      <div className={`${styles.academy} ${isRtl ? 'rtl' : ''}`}>
        <div className={styles.left}>
          <div ref={headerRef} className={`${styles.header}`}>
            <Text
              title="home.academy_in_numbers"
              variant="text-bold-italic-33"
              style={{ textTransform: 'uppercase' }}
              className={styles['mobile-title']}
              isLineBreak
            />
          </div>
          <div ref={contentRef} className={styles.content}>
            <Text
              title="home.leading_military"
              variant="text-bold-regular-25"
              className={styles.text}
            />
            <div className={styles.button}>
              <Button
                title="common.read_more"
                variant="text-regular-20"
                style={{ textTransform: 'uppercase' }}
                styleType="primary"
                className={styles.button}
                onClick={handleNavigate}
              />
            </div>
          </div>
        </div>
        <div ref={rightRef} className={styles.right}>
          <RewardCardGrid data={data} />
        </div>
        <div ref={buttonMobileRef} className={styles['button-mobile']}>
          <Button
            title="common.read_more"
            variant="text-regular-20"
            style={{ textTransform: 'uppercase' }}
            styleType="primary"
            onClick={handleNavigate}
          />
        </div>
      </div>
    </div>
  );
};

export default AcademyInNumber;
