import { apiSlice } from '../http/HttpRequest';

export const apiCall = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    get: builder.query({
      query: ({ apiUrl, params }) => {
        const API_URL = params ? apiUrl + params.join('/') : apiUrl;
        return {
          url: API_URL,
          method: 'GET',
          headers: {
            'Accept-Language': 'en',
          },
        };
      },
      onError: (error, arg, context) => {
        console.error('Error from onError:', error);
      },
    }),

    patch: builder.mutation({
      query: ({ apiUrl, formData }) => ({
        url: apiUrl,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'en',
        },
        body: JSON.stringify(formData),
      }),
    }),
    post: builder.mutation({
      query: ({ apiUrl, formData, type, params }) => {
        const API_URL = params ? apiUrl + params.join('/') : apiUrl;
        let headers = {};
        if (type !== 'formData') {
          headers['Content-Type'] = 'application/json';
          headers['Accept-Language'] = 'en';
        } else {
          headers['Accept-Language'] = 'en';
          
        }
        return {
          url: API_URL,
          method: 'POST',
          headers: headers,
          body: type === 'formData' ? formData : JSON.stringify(formData),
        };
      },
    }),
    put: builder.mutation({
      query: ({ apiUrl, formData }) => ({
        url: apiUrl,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'en',
        },
        body: JSON.stringify(formData),
      }),
    }),
  }),
});

export const {
  useGetQuery,
  usePostMutation,
  usePatchMutation,
  usePutMutation,
} = apiCall;
