const formatDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const formatter = new Intl.DateTimeFormat('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const formattedDate = formatter.format(date);
  return formattedDate;
};

export default formatDate;
