import React from 'react';
import styles from './Profile.module.scss';
import StudentsInfo from '../../../../../../components/molecule/studentInfo/StudentInfo';
import doubleQuoteLeft from '../../../../../../assets/icons/doubleQuoteLeft.svg';
import doubleQuoteRight from '../../../../../../assets/icons/doubleQuoteRight.svg';
import image from '../../../../../../assets/images/dummyImage.png';
import Backdrop from '../../../../../atoms/backdrop/Backdrop';
import { sanitizeAndValidateUrl } from '../../../../../../app/helpers/sanitizeAndValidateUrl';

const formatDate = (dateString, language) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Intl.DateTimeFormat(language, options)
    .format(date)
    .replace(/\//g, ' . ');
};

const ProfileOne = ({
  title,
  description,
  bgImage = image,
  isTestimonial,
  footerText,
  date,
  currentLanguage,
  isRtl,
}) => {
  const sanitizedBgImage = sanitizeAndValidateUrl(bgImage);

  return (
    <div
      className={`${styles['profile-container']} ${
        isRtl ? styles['profile-container-rtl'] : ''
      }`}
      style={{ backgroundImage: `url(${sanitizedBgImage})` }}
    >
      <Backdrop style={{ borderRadius: '10px', zIndex: 1 }} />
      <StudentsInfo
        isImageBackground
        doubleQuoteLeft={doubleQuoteLeft}
        doubleQuoteRight={doubleQuoteRight}
        title={title}
        description={description}
        containerClass={styles.student}
        isTestimonial={isTestimonial}
        footerText={footerText}
        date={date ? formatDate(date, currentLanguage) : ''}
        isRtl={isRtl}
      />
    </div>
  );
};

export default ProfileOne;
